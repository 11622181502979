import { useEffect, useRef } from "react";
import "./EyNexusComponent.scss";
import { HomeSection } from "../../Models/HomeModel";

interface EyNexusComponentProps {
  content: HomeSection | null;
}
const EyNexusComponent = ({ content }: EyNexusComponentProps) => {
  const vidRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (vidRef.current) {
      vidRef.current.play();
    }
  }, []);

  return (
    <div className="nexus-flex-row eynexus-container">
      <div className="nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-6 eynexus-left nexus-pr-8">
        <h6>{content ? content?.SectionSubtitle.toUpperCase() : ""}</h6>

        {content?.SectionTitle &&
          <>
            <h2 className="eynexus-title">
                      <span className="bold-txt">
                        {content.SectionTitle.split(" ").slice(0, 2).join(" ")}
                      </span>{" "}
                      {content?.SectionTitle.split(" ")
                      .slice(2, 6)
                      .join(" ")}
            </h2>
            <h2 className="eynexus-title-color">
              {content?.SectionTitle.split(" ")
              .slice(6, 9)
              .join(" ")}
            </h2>
            <h2 className="eynexus-title-color">
              {content?.SectionTitle.split(" ")
                        .slice(9, content?.SectionTitle.length)
                        .join(" ")}
            </h2>
          </>
        }

        {content?.SectionDescription &&
          <div className="nexus-mt-3">
            <h6 className="">
              {content?.SectionDescription.split(" ")
              .slice(0, 24)
              .join(" ")}
            </h6>
            <h6 className="nexus-mt-2">
              {content?.SectionDescription.split(" ")
              .slice(24, content?.SectionDescription.length)
              .join(" ")}
            </h6>
          </div>
        }
      </div>

      <div className="eynexus-right nexus-hidden-2xs nexus-hidden-xs nexus-hidden-sm nexus-hidden-md nexus-col-lg-6">
        <video
          data-testid="eynexus-video"
          className="eynexus-video"
          src="/videos/sequence01.mp4"
          ref={vidRef}
          muted
          autoPlay
          loop
        />
      </div>
    </div>
  );
};

export default EyNexusComponent;

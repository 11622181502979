import React from "react";
import { Accelerator } from "../../../../../../common/models/Accelerators";
import { AdoptSubPageStaticContent } from "../../../../../../common/models/AdoptPageStaticDetails";
import './AdoptSubPageBodyComponent.scss'
import { NexusButton, NexusCard, NexusCardBody, NexusCardBodyImage } from "@nexus/react";

const AdoptSubPageBodyComponent = (props: {
    adoptPageStaticDetails: AdoptSubPageStaticContent    
}) => {
    

    return (              
        <div className="adopt-subpage-body">
            <div className="nexus-row nexus-py-8 nexus-px-4">
                <div className="nexus-col-lg-12">
                    <h6 className="nexus-h6">{props.adoptPageStaticDetails.HowDoesWorkSection?.SectionQuestion}</h6>
                </div>
                <div className="nexus-col-lg-12 nexus-my-4">
                    <h3 className="nexus-h3">{props.adoptPageStaticDetails.HowDoesWorkSection?.SectionTitle}</h3>
                </div>
                <div className="nexus-col-lg-12">
                    <h6 className="nexus-h6">{props.adoptPageStaticDetails.HowDoesWorkSection?.SectionDescription}</h6>
                </div>       
            </div>        
            <div className="nexus-row nexus-py-6 nexus-px-4 explore-more">               
                <div className="nexus-col-lg-5  ">
                    <h3 className="nexus-h4">{props.adoptPageStaticDetails.ExploreMoreSection?.SectionText}</h3>                    
                </div>  

                <div className="nexus-col-lg-7 button-container">
                <NexusButton variant="link" size="auto" className={`nexus-py-1 nexus-px-3 request-demo-btn ${props.adoptPageStaticDetails.ExploreMoreSection?.ButtonLink !== null ? 'cursor-pointer' : 'cursor-default'}`}>
                    <a href={`${props.adoptPageStaticDetails.ExploreMoreSection?.ButtonLink}`} className={`request-demo-ahchor`} target={props.adoptPageStaticDetails.ExploreMoreSection?.ButtonLink !== "" ? "_blank" : ""}  >
                        {props.adoptPageStaticDetails.ExploreMoreSection?.ButtonCaption }
                    </a>
                </NexusButton>       
                </div>             
            </div>        
        </div>
    )
}

export default AdoptSubPageBodyComponent;
import {
  IconComunity,
  IconPrototyping,
  IconTraining,
} from "../../../../common/assets/images";

import "./ComingSoonComponent.scss";
import { useNavigate } from "react-router";
interface ComingSoonComponentProps {
  commingSoon?: string;
  community?: any | null;
  prototyping?: any | null;
  training?: any | null;
}

const ComingSoonComponent = ({
  commingSoon,
  community,
  prototyping,
  training,
}: ComingSoonComponentProps) => {
  const navigate = useNavigate()
  const trainingHandle = () => {
    navigate('/nexus/training')
  }
  return (
    <div className="nexus-flex-col comingsoon-container">
      <h6 className="comingsoon-title">
        {commingSoon ? commingSoon.toUpperCase() : ""}
      </h6>
      <div className="nexus-flex-row comingsoon-content">
        <div className="nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-6 comingsoon-left">
          <img src={IconComunity} alt="IconComunity" />
          <h2>{community ? community.Title : ""}</h2>
          <div className="comingsoon-description">
            {community ? community.Description : ""}
          </div>
        </div>
        <div className="nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-6 comingsoon-right">
          <div className="comingsoon-right-top">
            <img src={IconPrototyping} alt="IconComunity" />
            <h2>{prototyping ? prototyping.Title : ""}</h2>
            <div className="comingsoon-description">
              {prototyping ? prototyping.Description : ""}
            </div>
          </div>
          <div className="comingsoon-right-bottom" onClick={trainingHandle}>
            <img src={IconTraining} alt="IconComunity" />
            <h2>{training ? training.Title : ""}</h2>
            <div className="comingsoon-description">
              {training ? training.Description : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonComponent;

import "./NexusOperatingSystemComponent.scss";
import {
  BlockComponent,
  BlockHorizontalComponent,
} from "../../../../common/components/index";
import { HomeSection } from "../../Models/HomeModel";
import { NexusOperatingContent } from "../../Models/NexusOperatingModel";

interface NexusOperatingSystemComponentProps {
  headerData: HomeSection | null;
  content: NexusOperatingContent[] | null;
}

const NexusOperatingSystemComponent = ({
  headerData,
  content,
}: NexusOperatingSystemComponentProps) => {
  const { SectionTitle, SectionDescription } = headerData || {};

  return (
    <div className="opsys-container">
      <h3 className="opsys-title">{SectionTitle}</h3>
      <h6>{SectionDescription}</h6>
      <div className="nexus-flex-row opsys-items">
        {content &&
          content.map((item) => {
            if (item.size === "small") {
              return (
                <BlockComponent
                  id={item.id}
                  key={item.id}
                  icon={item.icon}
                  title={item.title}
                  subtitle={item.subtitle}
                  showButton={item.showButton}
                  buttonText={item.buttonText}
                  type={item.type}
                  showRibbon={item.showRibbon}
                  size={item.size}
                />
              );
            }
          })}
      </div>

      <div className="nexus-flex-row opsys-items">
        {content &&
          content.map((item) => {
            if (item.size === "large") {
              return (
                <BlockHorizontalComponent
                  id={item.id}
                  key={item.id}
                  icon={item.icon}
                  title={item.title}
                  subtitle={item.subtitle}
                  showButton={item.showButton}
                  buttonText={item.buttonText}
                  type={item.type}
                  showRibbon={item.showRibbon}
                  size={item.size}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export default NexusOperatingSystemComponent;

import React, { useEffect, useRef, useState } from "react";
import "./WhatsIncludedCatalogComponent.scss";
import { NexusButton, NexusCard, NexusCardBody, NexusCardFooter, NexusChip, NexusChipGroup, NexusInput, NexusPagination } from "@nexus/react";
import axios from "axios";
import { NexusPaginationCustomEvent } from "@nexus/core";
import { Link, useParams } from "react-router-dom";
import SharePointService from "../../../../../../common/modules/service/SharePointService";
import { useMsal } from "@azure/msal-react";

const WhatsIncludedCatalogComponent = (props: { mainPage: any, cardsPerPage: number, seeAllPresent: boolean }) => {

    const { mainPage, cardsPerPage, seeAllPresent } = props;
    const ref = useRef(null);

    const [showFilters, setShowFilters] = useState(false);
    const [simpleChipFilterNames, setSimpleChipFilterNames] = useState([]);
    const [selectedChipfilter, setSelectedChipfilter] = useState<string[]>(["All"]);
    const [searchValue, setSearchValue] = useState();
    const [cards, setCards] = useState([]);
    const [cardsCopy, setCardsCopy] = useState([]);
    const current = 1, attrId = 'connectEventPagination';
    const [currentPage, setCurrentPage] = useState(current);
    const [maxPaginationVal, setMaxPaginationVal] = useState<number>();
    const { instance, accounts } = useMsal();

    const params = useParams();
    const selectedItem = params.selecteditem;


    function getFiltersList() {
        let spService = new SharePointService();
        spService.getFilters(instance, accounts).then(response => response.json())
            .then(function (response) {
                console.log(response);
                const values = response.value;
                if (values.length > 0) {
                    setShowFilters(true);
                    let newValues = values.map((item: any) => {
                        return item.Filters
                    });
                    newValues.unshift("All");
                    setSimpleChipFilterNames(newValues);
                }
            })
    }

    function getCardsList() {

        if (cardsCopy.length > 0) {
            setCards(cardsCopy.slice(0, cardsPerPage));
            setMaxPaginationVal(Math.ceil((cardsCopy.length) / cardsPerPage));
        }
        else {
            let spService = new SharePointService();
            if (mainPage === "Connect") {
                spService.getConnectors(instance, accounts).then(response => response.json())
                    .then(function (response) {
                        const values = response.value;
                        if (values.length > 0) {
                            setCards(values.slice(0, cardsPerPage));
                            setCardsCopy(values);
                            setMaxPaginationVal(Math.ceil((values.length) / cardsPerPage));

                            searchSelectedItem(selectedItem, values);
                        }
                    })
            }
            else {
                spService.getComponents(instance, accounts).then(response => response.json())
                    .then(function (response) {
                        const values = response.value;
                        if (values.length > 0) {
                            setCards(values.slice(0, cardsPerPage));
                            setCardsCopy(values);
                            setMaxPaginationVal(Math.ceil((values.length) / cardsPerPage));

                            searchSelectedItem(selectedItem, values);
                        }
                    })
            }
        }
    }

    function searchSelectedItem(seletedItem: any, list: any) {
        if (seletedItem !== null && selectedItem !== undefined && selectedItem !== "") {
            const value = seletedItem;
            setSearchValue(value);
            if (value === "" || value === null || value === undefined) {
                getCardsList();
            }
            else {
                const filteredCards = list.filter((obj: any) => {
                    if (obj.Title.toLowerCase().includes(value.toLowerCase())) {
                        return true;
                    }
                    return false;
                });
                setCards(filteredCards);
            }
        }
    }

    useEffect(() => {
        getFiltersList();
        getCardsList();
    }, []);

    function setSimpleFilter(name: string) {
        let tempFilterArr = selectedChipfilter;
        const nameIndex = selectedChipfilter.indexOf(name);
        if (name !== "All" && nameIndex > -1) {
            tempFilterArr.splice(nameIndex, 1);
        }
        else if (name === "All") {
            tempFilterArr.length = 0;
            tempFilterArr.push("All");
            setCards(cardsCopy);
            setMaxPaginationVal(Math.ceil((cardsCopy.length) / cardsPerPage));
            setCurrentPageCards(1, cardsCopy);
            return;
        }
        else {
            const allIndex = tempFilterArr.findIndex(item => item === "All");
            if (allIndex > -1) {
                tempFilterArr.splice(allIndex, 1);
            }
            tempFilterArr.push(name);
        }
        setSelectedChipfilter(tempFilterArr);
        const filteredCards = cardsCopy.filter((item: any) => {
            if (Array.isArray(item.Category)) {
                return item.Category.some((element: any) => tempFilterArr.includes(element));
            }
            else if (item.Category?.length > 0 && tempFilterArr.includes(item.Category)) {
                return true;
            }
            else if (item.Category === null) {
                return false
            }
            else {
                return true;
            }
        });

        setCards(filteredCards);
        setMaxPaginationVal(Math.ceil((filteredCards.length) / cardsPerPage));
        setCurrentPageCards(1, filteredCards);
    }

    function isFilterSelected(name: string) {
        const index = selectedChipfilter.indexOf(name);
        if (index > -1) {
            return "nexus-selected-chip";
        }
        return "nexus-common-chip";
    }

    function handleSearch(e: any) {
        const { value } = e.target;
        setSearchValue(value);
        const tempCards = cardsCopy;
        if (value === "") {
            getCardsList();
        }
        else {
            const filteredCards = tempCards.filter((obj: any) => {
                if (obj.Title.toLowerCase().includes(value.toLowerCase())) {
                    return true;
                }
                return false;
            });
            setCards(filteredCards);
        }
    }

    function getDomainColor(name: string) {
        switch (name) {
            case "Banking":
                return "banking-cat-color";
            case "Insurance":
                return "insurance-cat-color";
            case "Cross Sector":
                return "universal-cat-color";
            case "Wealth & Asset":
                return "wealth-cat-color";
            case "Life":
                return "life-cat-color";
            case "Group Benefits":
                return "groupBenefits-cat-color";
            case "Claims":
                return "claims-cat-color";
            case "P&C":
                return "PAndC-cat-color";
            default:
                return "banking-cat-color";
        }
    }

    function getShortendDesc(desc: string) {
        if (desc) {
            return desc.length > 120 ? `${desc.substring(0, 140)}...` : desc;
        }
        return "";
    }

    function setCurrentPageCards(detail: any, cards: any) {
        setCurrentPage(detail);
        const startIndex = (detail - 1) * cardsPerPage;
        const currentCards = cards.slice(startIndex, startIndex + cardsPerPage);
        setCards(currentCards);
    }

    return (
        <div className="nexus-px-4 connect-event-cards-block">
            <div className="nexus-row nexus-mb-5 nexus-mt-4 ">
                <div className="nexus-col-lg-12 nexus-mb-1 whats-included-txt nexus-pt-4">
                    <h6 className="nexus-h6">WHAT'S INCLUDED</h6>
                </div>
                <div className="nexus-col-lg-12">
                    <h2 className="nexus-h2">{mainPage === "Connect" ? "Connectors " : "Component "} Catalog</h2>
                </div>
            </div>
            <div className="nexus-row mt-2 nexus-mb-5">
                <div className="nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-8 filter-chips">
                    {showFilters && <NexusChipGroup>
                        {simpleChipFilterNames.map((item: any) => <NexusChip
                            data-testid={`tstfilter${item}`}
                            key={item}
                            onClick={() => setSimpleFilter(item)}
                            className={`${isFilterSelected(item)} nexus-mr-1 `}
                        >
                            {item}
                        </NexusChip>)}
                    </NexusChipGroup>
                    }
                </div>
                <div className="nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4 search-container">
                    <label className="search-label">
                        <NexusInput
                            data-testid="tstSearch"
                            placeholder="Search"
                            style={{ width: "100%" }}
                            value={searchValue}
                            onInput={(event) => handleSearch(event)}
                        />
                    </label>
                </div>
            </div>
            <div className="nexus-row mt-3 pt-4 px-2 all-cards-block">
                {cards.length > 0 ? cards.map((item: any) => <div
                    className="nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4"
                    key={item.name}
                >
                    <NexusCard className="catalog-cards nexus-mb-3">
                        <NexusCardBody>
                            <div className="nexus-row nexus-mb-2 nexus-mt-4 nexus-px-3">
                                <h5 className="nexus-col-xs-4 nexus-h5 card-heading">
                                    {item.Title}
                                </h5>
                            </div>
                            <div className="nexus-row nexus-px-3 nexus-mb-1">
                                <p className="nexus-col-xs-4 nexus-subtitle-sm card-description">
                                    {getShortendDesc(item.Description)}
                                </p>
                            </div>
                            <div className="nexus-row nexus-mt-1 domain-container nexus-px-3 nexus-mb-3">
                                {item.Category?.length > 0 ? item.Category.map((categoryName: any) => <span
                                    key={categoryName}
                                    className="nexus-col-sm-auto nexus-col-md-auto nexus-col-lg-auto pr-1"
                                >
                                    <NexusChip
                                        className={`btn domainButton mt-3 ${getDomainColor(
                                            categoryName
                                        )}`}
                                    >
                                        {categoryName || ""}
                                    </NexusChip>
                                </span>) : <div style={{ height: "50px" }} />
                                }
                            </div>
                        </NexusCardBody>
                        <NexusCardFooter>
                            <div className="nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-6 nexus-px-3 nexus-py-1 docs-tryme-btn-block docs-btn-block">
                                <NexusButton variant="link" size="md">
                                    <a href={item.SlateUrl} target="_blank" className="docs-tryme-link">Docs</a>
                                </NexusButton>
                            </div>
                            <div className="nexus-col-xs-4 nexus-col-md-4 nexus-col-lg-6 nexus-px-3 nexus-py-1 docs-tryme-btn-block">
                                <NexusButton variant="link" size="md">
                                    <a href={item.SwaggerUrl} target="_blank" className="docs-tryme-link">Try Me</a>
                                </NexusButton>
                            </div>
                        </NexusCardFooter>
                    </NexusCard>
                </div>) : <div className="nexus-col-xs-4 mb-4">
                    <div className="nexus-center-xs nexus-mt-4 nexus-h6">
                        No data available
                    </div>
                </div>
                }
            </div>
            <div className="nexus-row nexus-py-4 nexus-mb-1 pagination-see-all-block">
                <div className="nexus-col-lg-6">
                    <NexusPagination
                        attrId={attrId}
                        current={currentPage}
                        max={maxPaginationVal}
                        onChangeEvent={(event: NexusPaginationCustomEvent<any>) => setCurrentPageCards(event.detail, cardsCopy)}>
                    </NexusPagination>
                </div>
                {seeAllPresent &&
                    <div className="nexus-col-lg-6 see-all-block">
                        <Link to="see-all" className="sell-all-link">See All</Link>
                    </div>
                }
            </div>
        </div>
    )
}

export default WhatsIncludedCatalogComponent;
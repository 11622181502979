import { useEffect, useState } from "react";
import RequestDemoComponent from "../common/ConnectEventPageLayouts/RequestDemo/requestDemoComponent";
import CatalogBlock from "../common/catalogBlock/catalogBlock";
import './AdoptComponent.scss'
import CardsComponent from "../../../../common/components/CardsComponent/CardsComponent";
import { AnimationData, MainSectionData, AcceleratorResponse, HowItsUsed } from "../../../Home/Models/common";
import WhatIsQuestionComponent from "../common/ConnectEventPageLayouts/WhatIsQuestion/whatIsQuestionComponent";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import SharePointService from "../../../../common/modules/service/SharePointService";

import TrainingNHelpComponent from "../common/Training&HelpComponent/Training&Help";

const AdoptComponent = () => {

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [loader, setLoader] = useState<boolean>(false);

    const [animationSectionData, setAnimationSectionData] = useState<AnimationData>({
        Title: "",
        HeaderDescription: "",
        DemoLink: { ButtonCaption: "", Email: "" },
        PageName: ""
    });

    const [whatIsQuestionData, setWhatIsQuestionData] = useState({
        Question: "",
        Header: "",
        Body: "",
        lastText: ""
    });
    const [itemResponse, setItemResponse]: any = useState<MainSectionData[]>([])
    const [catelogData, setCatelogData] = useState<AcceleratorResponse[]>([])
    const [howItsUsed, setHowItsUsed]: any = useState<HowItsUsed[]>([])
    const [trainingInfo, setTrainingInfo]: any[] = useState([])
    const [helpInfo, setHelpinfo]: any[] = useState([])

    const fetchAccelerators = async (spService: any) => {
        await spService.getAccelerators(instance, accounts)
            .then((response: { json: () => any; }) => response.json())
            .then((data: { value: any; }) => {
                // setCatelogData(data.value)
                getAcceleratorImages(data.value, spService)
            }).catch((error: any) => {
                console.error(error);
            });
    }

    const onLoadFetch = async (spService: any) => {
        await spService.getDevPortalPageDetails(instance, accounts).then((response: { json: () => any; }) => response.json())
            .then((data: { value: any[]; }) => {
                let itemArr: MainSectionData[] = []
                data.value.forEach((item: any) => {
                    if (item.PageName !== 'Adopt') {
                        itemArr.push(item)
                    }
                })
                getHowItUsedImages(itemArr, spService)
                data.value.forEach((item: any) => {
                    if (item.PageName === 'Adopt') {
                        setAnimationSectionData(item);
                        const parsedWhatIsQuestionData = JSON.parse(item.WhatIs);
                        setWhatIsQuestionData(parsedWhatIsQuestionData);
                        setHowItsUsed(JSON.parse(item.HowItsUsed))
                    }
                });
                getStaticData(spService)
                fetchAccelerators(spService)
            })
            .catch((error: any) => {
                console.error(error);
            });
    }

    const getHowItUsedImages = async (data: any, spService: any) => {
        const sectionData: any = []
        const howIsList = [...data]
        let ListName = spService.ListNames.DEV_PORTAL_PAGE_DETAILS
        data.forEach((element: any) => {
            if (element.CardImage !== null) {
                let item = element
                let file = JSON.parse(element.CardImage)
                spService.getAttachments(ListName, element.Id, encodeURI(file.fileName), instance, accounts)
                    .then((data: any) => {
                        item.imgUrl = data
                        sectionData.push(item)
                        const index = howIsList.findIndex((obj: any) => obj.Id === sectionData.Id);
                        if (index !== -1) {
                            howIsList[index] = sectionData
                        }
                        setItemResponse(howIsList)
                    }).catch((err: any) => {
                        console.log(err.message)
                    })

            }
        });
    }

    const getAcceleratorImages = async (data: any, spService: any) => {
        const sectionData: any = []
        const acceList = [...data]
        let ListName = spService.ListNames.ACCELERATORS
        data.forEach((element: any) => {
            if (element.CardImage !== null) {
                let item = element
                let file = JSON.parse(element.CardImage)
                spService.getAttachments(ListName, element.Id, encodeURI(file.fileName), instance, accounts)
                    .then((data: any) => {
                        item.imgUrl = data
                        sectionData.push(item)
                        const index = acceList.findIndex((obj: any) => obj.Id === sectionData.Id);
                        if (index !== -1) {
                            acceList[index] = sectionData
                        }
                        setCatelogData(acceList)
                    }).catch((err: { message: any; }) => {
                        console.log(err.message)
                    })
            }
        });
    }

    const getStaticData = (spService: any) => {
        setLoader(true)
        spService.getDevPortalStaticContents(instance, accounts)
            .then((response: { json: () => any; }) => response.json())
            .then((data: any) => {
                let trainingData: any = []
                let helpingData: any = []
                data.value.map((item: any) => {
                    if (item.Title === "SectionTraining") {
                        const itemData = JSON.parse(item.Content)
                        trainingData.push(itemData)
                    }
                })
                data.value.map((item: any) => {
                    if (item.Title === "SectionHelp") {
                        const helps = JSON.parse(item.Content)
                        helpingData.push(helps)
                    }
                })
                setTrainingInfo(trainingData)
                setHelpinfo(helpingData)
                setLoader(false)
            }).catch((err: any) => {
                console.log(err)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        let spService = new SharePointService()
        onLoadFetch(spService)
    }, [])

    return (
        <div style={{ overflow: 'hidden' }}>
            <RequestDemoComponent animationSectionData={animationSectionData} />
            <WhatIsQuestionComponent whatIsQuestionData={whatIsQuestionData} />
            <div className="nexus-row nexus-flex-col nexus-py-8 nexus-pl-5">
                <div className="nexus-col-12">
                    <h6 className="nexus-h6" data-testid='whatInclude'>WHAT'S INCLUDED</h6>
                    <h2 className="nexus-h2" data-testid='whatIncludeTitle'>Accelerator's Catalog</h2>
                </div>
            </div>
            <div className="item-container">
                {catelogData.length > 0 ?
                    <CatalogBlock sectionData={catelogData} />
                    : <p>Loading..</p>}
            </div>
            <div className="how-it-used">
                <div className="how-it-used-container">
                    <h6 className="nexus-h6" data-testid='howItUsed'>HOW IT'S USED</h6>
                    <h2 className="nexus-h2" >{howItsUsed.Title}</h2>
                    <p className="nexus-h6">{howItsUsed.Description}</p>
                    <div className="cards-list ">
                        {itemResponse.map((cardItem: any, idx: any) => (
                            <CardsComponent title={cardItem.Title} key={idx} description={cardItem.HeaderDescription} image={cardItem.imgUrl} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="nexus-flex-row training-container">
                {Object.keys(trainingInfo).length > 0 ? <TrainingNHelpComponent trainings={trainingInfo} helpData={helpInfo} /> :
                    <p>loading...</p>
                }
            </div>
        </div>
    )
}

export default AdoptComponent
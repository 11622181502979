// msalConfig.ts
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig:Configuration = {
    auth: {
      clientId: 'b7c0cf9d-388a-4887-8bdb-09326e69ffb6',
      authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',          
      redirectUri: window.location.origin,
      navigateToLoginRequestUrl: false,
      
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    }
};
  
export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read','AllSites.Read'],        
};

export const spTokenRequest = {
  scopes : ['https://sites.ey.com/.default']
}


export const msalInstance = new PublicClientApplication(msalConfig);
import { NexusProgressBar } from "@nexus/react";

const LoaderComponent: React.FC = () => {
  return (
    <div className="loader-wrapper" data-testid="loader-wrapper">
      <NexusProgressBar
        circle
        indeterminate
        className="loader-div"
        data-testid="loader-div"
      />
    </div>
  );
};

export default LoaderComponent;

import React, { useEffect, useState, useRef } from "react";
import { NexusButton } from "@nexus/react";
import PropTypes from 'prop-types';
import "./requestDemoComponent.scss";

type RequestDemoProps = {
  Title: string;
  HeaderDescription: string;
  DemoLink: { ButtonCaption: string, Email: string };
  PageName: string
};

const RequestDemoComponent = (props: { animationSectionData: RequestDemoProps }) => {

  const { animationSectionData } = props;

  const mainPage = animationSectionData.PageName;

  const connectAnimationRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (connectAnimationRef.current && mainPage) {
      connectAnimationRef.current.play();
    }
  }, []);

  return (
    <div className="nexus-row request-demo-block">
      <div className="nexus-col-xs-12 nexus-col-lg-6 nexus-py-8 nexus-pl-4">
        <h1 className="nexus-h1">{animationSectionData.Title}</h1>
        <p className="nexus-my-3 nexus-body">{animationSectionData.HeaderDescription}</p>
        <NexusButton variant="link" size="auto" className={`nexus-py-1 nexus-px-3 request-demo-btn ${animationSectionData.DemoLink.Email !== null ? 'cursor-pointer' : 'cursor-default'}`}>
          <a href={`${animationSectionData.DemoLink.Email !== "" ? "mailto:" + animationSectionData.DemoLink.Email : ""}`} className={`request-demo-ahchor ${animationSectionData.DemoLink.Email !== null ? 'auto-pointer-events cursor-pointer' : 'none-pointer-events cursor-default'}`}>
            {animationSectionData.DemoLink.ButtonCaption || "Request Demo"}
          </a>
        </NexusButton>
      </div>
      <div className="nexus-col-xs-12 nexus-col-lg-6">
        <video src={`/videos/${mainPage}.mp4`} className="req-demo-video" ref={connectAnimationRef} muted autoPlay loop>
          Sorry, your browser doesn't support embedded videos, but don't worry, you can
          <a href={`/videos/${mainPage}.mp4`}>download it</a>
          and watch it with your favorite video player!
        </video>
      </div>
    </div>
  )
}

RequestDemoComponent.propTypes = {
  animationSectionData: {
    Title: PropTypes.string,
    HeaderDescription: PropTypes.string,
    DemoLink: PropTypes.string,
  }
};

export default RequestDemoComponent;

import React from "react";
import ConnectEventCombinedComponent from "../common/ConnectEventPageLayouts/ConnectEventCombined/ConnectEventCombinedComponent";

const ConnectComponent = () => {

    return (
        <ConnectEventCombinedComponent mainPage="Connect" />
    )
}

export default ConnectComponent;

import { NexusIcon, NexusInput, NexusSearch, NexusSearchList, NexusSearchListItem } from "@nexus/react";
import { SearchLeft, SearchRight2 } from "../../../../common/assets/images";
import IcSearch from "@nexus/core/dist/assets/icons/action/ic_search_24px.svg";
import "./NexusSearchComponent.scss";
import { useEffect, useState } from "react";
import SharePointService from "../../../../common/modules/service/SharePointService";
import { useMsal } from "@azure/msal-react";

interface NexusSearchComponentProps {
  title: string;  
}

let totalItems:any = [];

const NexusSearchComponent = ({ title }: NexusSearchComponentProps) => {

  const [filtereditem, setFiltereditem] = useState([]); 
  const { instance, accounts } = useMsal();  
  const [ showSearchResult , setShowSearchResult  ] = useState(false);
  const [ searchItemsLoaded,  setSearchItemsLoaded ]= useState(false);

  
  let apiCalled:any = false;
  
  const onKeyUp =(event:any)  => {
    if(searchItemsLoaded)
      Performfilter(event.target.value);      
    else
      setTimeout( () => Performfilter(event.target.value), 1500)
  }

  const Performfilter = (keyword:any) => {             
      if (keyword.length > 2) {          
        setShowSearchResult(true);
        const results:any = totalItems.filter((item:any) => item?.Title.toLowerCase().indexOf(keyword.toLowerCase()) !== -1);      
        setFiltereditem(results);
      } else {
        setFiltereditem([]);
        setShowSearchResult(false);
      }           
  };

  useEffect(() => {
    if(!apiCalled){
      getFilteredDataFromSP("", 500, 0);
      apiCalled = true;
    }
  }, []);


  const getFilteredDataFromSP = (keyword:any, rowCount:any, startRow:any) => {
    
    const service = new SharePointService();

    // Specify the properties to select
    // Formulate the search query using the path to the list and the title    
    const searchQuery = `contentclass:STS_ListItem_GenericList AND (Path:${service.SharePointSiteURL}/lists/${ "Component" } OR Path:${service.SharePointSiteURL}/lists/${service.ListNames.CONNECTORS})`;    
    
    const selectProperties:any = 'Title,SPWebUrl';
    service.getDataUsingSearchAPI(instance, accounts,searchQuery, selectProperties, rowCount, startRow).then((response: { json: () => any; }) => response.json())
      .then((res: any) => {

        let locItems:any = totalItems;

        res.PrimaryQueryResult.RelevantResults.Table.Rows.forEach((element:any) => {

          // result object
          var item:any = {};
          element.Cells.forEach(function (cell:any) {    
            item[cell.Key] = cell.Value
          })

          locItems.push(item)         
          
        });
                
        totalItems = locItems;
        
        if(locItems.length < res.PrimaryQueryResult.RelevantResults.TotalRows) { 
          let newStartRow = locItems.length;
          getFilteredDataFromSP(keyword, rowCount, newStartRow);
        }       
        else {
          setSearchItemsLoaded(true);
          console.log(totalItems);         
        }
       
      }).catch((err: any) => {
        setSearchItemsLoaded(true);
        setFiltereditem([]);
        console.error("Error in fetching home data", err);       
        
      })
  }


  return (
    <>
      <div className="nexus-flex-row nexus-search-container ">
        <div className="nexus-hidden-2xs nexus-hidden-xs nexus-hidden-sm nexus-hidden-md nexus-col-lg-2 nexus-start-lg nexus-flex-end">
          <img src={SearchLeft} alt="SearchLeft" />
        </div>
        <div className="nexus-col-2xs-4 nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-8 nexus-col-lg-7 nexus-search-center">
          <div className="nexus-search-title">{title}</div>
          <div className="">
          <NexusSearch>
            <NexusInput
              attrId="simple-input-filed"
              placeholder="Search"
              type="text"
              data-testid="input-text-box"
              className="nexus-search-input"
              onKeyUp={onKeyUp}
            >
              <NexusIcon src={IcSearch} size="md" />
            </NexusInput>
            <NexusSearchList className="nexus-search-list-custom">            
              {filtereditem.length > 0 ? (
                <div>
                  {filtereditem.map((item:any) => (
                    <NexusSearchListItem  data-testid="search-list" key={item?.IdentityListItemId + item?.Title }>
                       <a  href={item.Path.indexOf("Connectors") >-1 ?  `/nexus/connect/see-all/${item.Title}` : `/nexus/event/see-all/${item.Title}`}> 
                        <button >
                          <span className="search-item-name ">{item?.Title}</span>
                          <div>
                            <span className="search-item-type ">{item.Path.indexOf("Connectors") > -1 ? "Nexus Connect" : "Nexus Event" }</span>
                          </div>                        
                        </button>                      
                        </a> 
                    </NexusSearchListItem>
                  ))}
                </div>
              ) :   showSearchResult && (<div className="search-no-results">No results found</div>) }            
            </NexusSearchList>
            </NexusSearch>
          </div>
        </div>
        <div className="  nexus-hidden-2xs nexus-hidden-xs nexus-hidden-sm nexus-hidden-md nexus-col-lg-3 nexus-end-lg nexus-p-0 nexus-flex-end">
          <img src={SearchRight2} alt="SearchRight2" />
        </div>
      </div>
    </>
  );
};

export default NexusSearchComponent;

import React from "react";
import AccordionGroupComponent from "../../../../../common/components/AccordionGroup/AccordionGroup";
import { NexusButton } from "@nexus/react";
import './BootCamp.scss'

const BootCampComponent = (props: any) => {
    return (
        <>
            <div className="nexus-row nexus-pb-5 nexus-px-4 cap-acc-block">
                {props.accordionData?.Capabilities.length > 1 ?
                    <AccordionGroupComponent accordionData={props.accordionData} />
                    : null}
            </div>
            <div className="nexus-row nexus-py-6 nexus-px-4 explore-more">
                <div className="nexus-col-lg-5  ">
                    <h3 className="nexus-h4">{props.exploreData?.SectionDescription}</h3>
                </div>

                <div className="nexus-col-lg-7 button-container">
                    <NexusButton variant="link" size="auto" className={`nexus-py-1 nexus-px-3 request-demo-btn`}>
                        <a href={`${props.exploreData?.ButtonLink}`} className={`request-demo-ahchor`} target={'props.adoptPageStaticDetails.ExploreMoreSection?.ButtonLink !== "" ? "_blank" : ""'}  >
                            {props.exploreData?.ButtonTitle}
                        </a>
                    </NexusButton>
                </div>
            </div>
        </>
    )
}

export default BootCampComponent
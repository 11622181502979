export const AppRouterPaths = Object.freeze({
  // Main routes
  root: "/",
  connectMainPage: "connect",
  eventMainPage: "event",
  adoptMainPage: "adopt",
  adoptDetailsPage: "adopt-details",
  TrainingPage: "training",
  noMatch: "*",
  seeAll: "see-all",
});

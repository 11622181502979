import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  NexusAccordion,
  NexusAccordionTrigger,
  NexusAccordionContent,
  NexusAccordionGroup,
  NexusIcon
} from '@nexus/react';
import "./AccordionGroup.scss";

const AccordionWrapperComponent = (props: {
  toggleAccordion: boolean;
  icon: string;
  setToggleHandler: (arg: boolean) => void;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  position: 'start' | 'center' | 'end';
  header: React.ReactNode;
  content: any;
  contentHeader?: 'string';
  videoLink?: string;
  duration?: string;
}) => {
  return (
    <NexusAccordion
      open={props.toggleAccordion}
      onToggleEvent={() => props.setToggleHandler(!props.toggleAccordion)}
      size={'lg'}
    >
      <NexusAccordionTrigger align={props.position} className='connect-event-acc-trigger'>
        <h1 className="nexus-body">{props.header}</h1>
      </NexusAccordionTrigger>
      <NexusAccordionContent>
        <NexusIcon src={props.icon}></NexusIcon>
        {props.content &&
          <div>
            {props.contentHeader &&
              <h4 className='nexus-h4 content-container'>{props.contentHeader}</h4>
            }
            <div className='details-button-container'>
              <ul style={{ width: '100%' }}>
                {props.content.map((item: any, index: any) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
              {props.contentHeader &&
                <div className='button-container'>
                  <a href={props.videoLink} className="nexus-btn-primary nexus-btn-auto nexus-my-2" target="_blank">Watch Course Recording</a>
                  <p className='nexus-subtitle-sm btn-sub-text'> Duration: {props.duration}</p>
                </div>
              }
            </div>
          </div>
        }
      </NexusAccordionContent>
    </NexusAccordion>
  )
};

const AccordionGroupExample = (props: any) => {

  const [open, setOpen] = useState(props.accordionState[props.index]);
  useEffect(() => setOpen(props.accordionState), [props.accordionState]);
  return (
    <AccordionWrapperComponent
      toggleAccordion={open}
      header={props.title}
      content={props.info}
      icon={props.title}
      setToggleHandler={() => props.stateHandler(props.index)}
      size={'lg'}
      position={'start'}
      contentHeader={props.contentHeader}
      videoLink={props.videoLink}
      duration={props.duration}
    />
  );
};



const getData = (accordionData: any, selectedIdx: any, existingState: { [id: string]: boolean }) => {
  const states: { [id: string]: boolean } = {};
  accordionData.Capabilities.forEach((_: any, idx: number) => {
    states[idx] = (idx === selectedIdx ? !existingState[idx] : false);
  });
  return states;
};


export const AccordionGroupComponent = (props: any) => {

  const [accordionStates, setAccordionState] = useState(getData(props.accordionData, -1, {}));
  const setAccordionStateHandler = (idx: string | number) => {
    const states = getData(props.accordionData, idx, accordionStates);
    setAccordionState(states);
  };

  return (
    <>
      <div className='nexus-col-lg-12 acc-question nexus-mb-1'>
        <h6 className='nexus-h6'>{props.accordionData.Question}</h6>
      </div>
      <div className='nexus-col-lg-12 acc-title nexus-mb-4'>
        <h2 className='nexus-h2'>{props.accordionData.Title}</h2>
      </div>
      <NexusAccordionGroup>
        {props.accordionData.Capabilities.map((rec: { Title: any; Description: any; contentHeader: any; VideoLink: any, CourseDuration: any }, idx: string | number) => (
          <AccordionGroupExample
            key={idx}
            data-testid="acc-group"
            title={rec.Title}
            info={rec.Description}
            stateHandler={setAccordionStateHandler}
            index={idx}
            accordionState={accordionStates[idx]}
            contentHeader={rec.contentHeader}
            videoLink={rec.VideoLink}
            duration={rec.CourseDuration}
          />
        ))}
      </NexusAccordionGroup>
    </>
  );
};

AccordionWrapperComponent.propTypes = {
  toggleAccordion: PropTypes.bool,
  setToggleHandler: PropTypes.func,
  position: PropTypes.string,
  size: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.array
};

// AccordionWrapperComponent.defaultProps = {
//   position: 'start',
//   size: 'lg',
//   header: 'Accordion Title',
//   content: 'Default accordion text.'
// };

export default AccordionGroupComponent;
import "./HeaderComponent.scss";
import { NexusLogo } from "../../assets/images";
import { Link, useLocation, useParams } from "react-router-dom";
import { useState } from "react";

const HeaderComponent = () => {

  const location = useLocation();
  
  // Function to check if the path is the current path
  const isActive = (path:any) => {
    return location.pathname.indexOf(path) > -1;
  };
  
  return (
    <div className="header-container">
      <div className="header-logo">
        <img src={NexusLogo} alt="Home" />
      </div>
      <div className="header-menu">
        <Link className="header-link" to="/">
          <div className="menu-item active">
            <div>Home</div>            
            {location.pathname === '/' && <div className="menu-item-decorator"></div>}
          </div>
        </Link>

        <div className="header-link">
          <div className="menu-item" >Operating System

          {(isActive('/nexus/connect') || isActive('/nexus/event') || isActive('/nexus/adopt')) && <div className="menu-item-decorator"></div>} 
          {/* {isSubMenuVisible && ( */}
          <div className="submenu">
            <Link className="header-link" to="/nexus/connect">
              <div className="submenu-item">Nexus Connect</div>
            </Link>
            <Link className="header-link" to="/nexus/event">
              <div className="submenu-item">Nexus Event</div>
            </Link>
            <Link className="header-link" to="/nexus/adopt">
              <div className="submenu-item">Nexus Adopt</div>
            </Link>
          </div>
        {/* )} */}
          </div>         

        </div>

        <Link className="header-link" to="/community">
          <div className="menu-item">Community</div>
        </Link>
      </div>
    </div>
  );
};

export default HeaderComponent;

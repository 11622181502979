import React, { useState, useEffect } from "react";
import './Training.scss'
import bannerImg from '../../../../common/assets/images/training.png'
import { NexusTabBar } from '@nexus/react';
import BootCampComponent from "../common/BootCamp/BootCamp";
import TechnicalComponent from "../common/Technical/Technical";
import SharePointService from "../../../../common/modules/service/SharePointService";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const tabBarCustomContent = [
    { title: 'Nexus Bootcamp', isActive: true },
    { title: 'Technical Training', isActive: false },
]

const TrainingPageDetails = () => {
    const [newtabOption, setnewtabOption] = useState(tabBarCustomContent);
    const [tabselect, setTabSelect] = useState(0)
    const [staticData, setStaticData] = useState(
        {
            SectionTitle: '',
            SectionDescription: ''
        }
    )
    const [tabHeaders, setTabHeaders]: any = useState([])
    const [exploreMore, setExploreMore] = useState()
    const [bootCampData, setBootCampData]: any = useState(
        {
            Title: '',
            Question: '',
            Capabilities: [
                {
                    Title: '',
                    Capabilities: []
                    ,
                    contentHeader: ''
                }
            ]
        }
    )
    const [trainingData, setTrainingData]: any = useState()
    const [dataLoading, setDataLoading] = useState(false)
    const { instance, accounts } = useMsal();

    const getTrainingPageStaticDetails = async (spService: any) => {
        setDataLoading(true)
        await spService.getDevPortalStaticContents(instance, accounts)
            .then((response: { json: () => any; }) => response.json())
            .then((data: any) => {
                data.value.forEach((element: any) => {
                    if (element.Title === "TrainngPage_BannerSection") {
                        setStaticData(JSON.parse(element.Content))
                    } else if (element.Title === "TrainingPage_TabcaptionAndHeader") {
                        let tabs: any = []
                        JSON.parse(element.Content).map((itm: any, inx: number) => {
                            const newTbs =
                                { title: itm.TabName, isActive: inx == 0 ? true : false }
                            tabs.push(newTbs)
                        })
                        setTabHeaders(tabs)
                    } else if (element.Title === "TrainingPage_ExploreMoreSection") {
                        setExploreMore(JSON.parse(element.Content))
                    }
                });
                setDataLoading(false)
            }).catch((err: any) => {
                console.log(err)
                setDataLoading(false)
            })
    }

    const getBootCampData = async (spService: any) => {
        setDataLoading(true)
        await spService.getBootCamp(instance, accounts).then((response: { json: () => any; }) => response.json())
            .then((data: any) => {
                let bootD: any = []
                let accordionObj: any = {
                    Title: 'Explore the Modules',
                    Question: '',
                    Capabilities: []
                }
                data.value.forEach((element: any) => {
                    const tempObj: any = {
                        Title: element.Title,
                        Description: JSON.parse(element.CourseObjective
                        ),
                        contentHeader: 'Course Objectives',
                        CourseDuration: element.CourseDuration,
                        VideoLink: element.VideoLink
                    }
                    accordionObj.Capabilities.push(tempObj)
                    bootD.push(tempObj)
                });
                setBootCampData(accordionObj)
                setDataLoading(false)
            }).catch((err: any) => {
                console.log(err)
                setDataLoading(false)
            })

    }

    const getTechnicalTrainings = async (spService: any) => {
        await spService.getTechnicalTrainnigs(instance, accounts).then((response: { json: () => any; }) => response.json())
            .then((data: any) => {
                getTechnicalImages(data.value, spService)
            }).catch((err: any) => {
                console.log(err)
            })
    }

    const getTechnicalImages = async (data: any, spService: any) => {
        const sectionData: any = []
        const acceList = [...data]
        let ListName = spService.ListNames.NEXUS_TECH_TRAINING
        data.forEach((element: any) => {
            if (element.Image !== null) {
                let item = element
                let file = JSON.parse(element.Image)
                spService.getAttachments(ListName, element.Id, encodeURI(file.fileName), instance, accounts)
                    .then((data: any) => {
                        item.imgUrl = data
                        sectionData.push(item)
                        const index = acceList.findIndex((obj: any) => obj.Id === sectionData.Id);
                        if (index !== -1) {
                            acceList[index] = sectionData
                        }
                        setTrainingData(acceList)
                    }).catch((err: { message: any; }) => {
                        console.log(err.message)
                    })
            }
        });
    }

    useEffect(() => {
        let spService = new SharePointService()
        getTrainingPageStaticDetails(spService)
        getBootCampData(spService)
        getTechnicalTrainings(spService)
    }, [])


    const ChangeActive = (index: number) => {
        const selectedTab = [...tabHeaders];
        selectedTab.forEach((option) => {
            option.isActive = false;
        });
        setTabSelect(index)
        selectedTab[index].isActive = true;
        setTabHeaders(selectedTab);
    };

    return (
        <div style={{ overflow: 'hidden' }} >
            <div className="banner-container">
                <div className="banner-text-container">
                    <h2 >{staticData.SectionTitle}</h2>
                    <h4 className="nexus-h4">{staticData.SectionDescription}</h4>
                </div>
                <div className="banner-img-container">
                    <img src={bannerImg} alt="banner-vector" />
                </div>
            </div>
            <div className="tab-container">
                {!dataLoading ?
                    <>
                        <NexusTabBar>
                            <span>
                                {tabHeaders.map((option: any, index: number) =>
                                    option.isActive ? (
                                        <button
                                            data-testid="tab-bar-active"
                                            key={index}
                                            className="nexus-link nexus-active"
                                            onClick={(_event) => ChangeActive(index)}
                                        >
                                            {option.title}
                                        </button>
                                    ) : (
                                        <button
                                            data-testid="tab-bar"
                                            key={index}
                                            className="nexus-link"
                                            onClick={(_event) => ChangeActive(index)}
                                        >
                                            {option.title}
                                        </button>
                                    )
                                )}
                            </span>
                        </NexusTabBar>
                        <div className="tab-content-container">
                            {tabselect === 0 ?
                                <BootCampComponent exploreData={exploreMore} accordionData={bootCampData} />
                                : <TechnicalComponent trainData={trainingData} />
                            }
                        </div>
                    </>
                    : <p>Loading...</p>
                }


            </div>
        </div>
    )
}


export default TrainingPageDetails
import React from "react";
import './CardsComponent.scss'
import { NexusCard, NexusCardBody, NexusCardBodyImage, NexusCardFooter } from '@nexus/react';
import CardImg from '../../assets/images/cardImg.png'

const CardsComponent = (props: any) => {
    return (
        // <div className="nexus-col-xs-12 nexus-col-sm-12 nexus-col-md-3 nexus-col-lg-3 nexus-col-xl-3">
        // <NexusCard>
        //     <NexusCardBody  >
        //         <NexusCardBodyImage>
        //             <img src={props.image} alt="image" style={{ height: 'auto' }} />
        //         </NexusCardBodyImage>

        //         <h6 data-testid="body-with-image-body" className="nexus-h6">
        //             {props.title}
        //         </h6>
        //         <p data-testid="body-with-image-bodyend">
        //             {props.description}
        //         </p>
        //     </NexusCardBody>
        // </NexusCard>
        // </div>
        <div className="cards-container">
            <div className="card">
                <img src={props.image ? props.image : CardImg} alt="Card Image" />
                <div className="card-text-container">
                    <h6 className="nexus-h6">{props.title}</h6>
                    <p className="card-description"> {props.description}</p>
                </div>

            </div>
        </div>
    )
}

export default CardsComponent
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { msalInstance } from "./common/modules/azureAuth/authConfig";
import { MsalProvider } from "@azure/msal-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <BrowserRouter>    
      <App />
    </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);

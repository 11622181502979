import { Link } from "react-router-dom";
import "./BlockComponent.scss";
import { NexusOperatingContent } from "../../../features/Home/Models/NexusOperatingModel";

const BlockComponent = ({
  icon,
  title,
  subtitle,
  showButton,
  buttonText,
  type,
  showRibbon,
}: NexusOperatingContent) => {
  return (
    <div className="nexus-flex-col block-container">
      <div className="block-icon-container">
        <img src={icon} alt={title} className="block-icon" />
      </div>

      <h4 className="block-title">{title}</h4>
      <div className="block-subtitle">{subtitle}</div>
      {showButton && (
        <Link to={`nexus/${type}`}>
          <button className="block-button">{buttonText}</button>
        </Link>
      )}
      {showRibbon && <div className="ribbon">Coming Soon!</div>}
    </div>
  );
};

export default BlockComponent;

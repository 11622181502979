import { Link } from "react-router-dom";
import { NexusOperatingContent } from "../../../features/Home/Models/NexusOperatingModel";
import "./BlockHorizontalComponent.scss";

const BlockHorizontalComponent = ({
  icon,
  title,
  subtitle,
  showButton,
  buttonText,
  type,
  showRibbon,
}: NexusOperatingContent) => {
  return (
    <div className="nexus-flex-row block-horizontal-container">
      <div className="nexus-flex-row">
        <div className="block-horizontal-icon-container">
          <img src={icon} alt={title} className="block-icon" />
        </div>

        <div className="block-horizontal-text-container">
          <div className="block-horizontal-enabled">ENABLED BY</div>
          <h4 className="block-horizontal-title">{title}</h4>
          <div className="block-horizontal-subtitle">{subtitle}</div>
        </div>
      </div>

      {showButton && (
        <Link to={`nexus/${type}`}>
          <button className="block-horizontal-button">{buttonText}</button>
        </Link>
      )}
      {showRibbon && <div className="ribbon">Coming Soon!</div>}
    </div>
  );
};

export default BlockHorizontalComponent;

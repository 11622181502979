import { Accelerator } from "../../../../../../common/models/Accelerators";
import { AdoptSubPageStaticContent } from "../../../../../../common/models/AdoptPageStaticDetails";
import './AdoptSubPageBannerComponent.scss'
import { NexusButton } from "@nexus/react";

const AdoptSubPageBannerComponent = (props: {
    adoptPageStaticDetails: AdoptSubPageStaticContent
    adoptItemDetails: Accelerator
}) => {  

    return (        
        <div className="nexus-row adopt-subpage-banner">
             <div className="nexus-col-xs-12 nexus-col-lg-6 nexus-py-5 nexus-pl-4 banner-left-container ">
                    <h1 className="nexus-h2">{props.adoptItemDetails?.Title}</h1>
                    <p className="nexus-my-3 nexus-body">{props.adoptItemDetails?.Description}</p>
                    <div className="nexus-flex-row banner-button-container">
                    {props.adoptPageStaticDetails?.BannerButtons?.map((item: any, idx: any) => (
                    <NexusButton variant="link" size="auto" key={idx} className={`nexus-py-1 nexus-px-3-1 nexus-m request-demo-btn ${item.ButtonLink !== null ? 'cursor-pointer' : 'cursor-default'}`}>
                        <a href={`${item.ButtonLink}`} className={`request-demo-ahchor`} key={idx} target={item.ButtonLink !== "" ? "_blank" : ""}>
                            {item.ButtonCaption }
                        </a>
                    </NexusButton>                         
                    ))} 
                    </div>                              
             </div>
             <div className="nexus-col-xs-12 nexus-col-lg-6 nexus-py-4 nexus-pr-4">
                 {                    
                    props?.adoptPageStaticDetails?.BannerVideoLink === null || props?.adoptPageStaticDetails?.BannerVideoLink === "" || props?.adoptPageStaticDetails?.BannerVideoLink === undefined? <img src="/CardPlaceHolder.jpg" alt="lap"></img>
                    :                   
                    <video
                    data-testid="eynexus-video"
                    className="eynexus-video"
                    src={`/videos/`+props.adoptPageStaticDetails?.BannerVideoLink}                    
                    muted
                    autoPlay
                    loop
                  />
                } 
             </div>
         </div>
    )
}

export default AdoptSubPageBannerComponent;
import { GetStarted } from "../../../../common/assets/images";
import "./GetStartedComponent.scss";

interface GetStartedComponentProps {
  content: any;
}

const GetStartedComponent = ({ content }: GetStartedComponentProps) => {
  return (
    <div className="getstarted-container">
      <div>
        <img src={GetStarted} alt="GetStarted" />
      </div>
      <div>
        <h2>{content ? content.SectionTitle : ""}</h2>
        <h4 className="getstarted-description">
          {content ? content.SectionDescription.Text : ""}
        </h4>
        <h4>nexus.support@ey.com</h4>
      </div>
    </div>
  );
};

export default GetStartedComponent;

import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from 'prop-types';
import "./ConnectEventCombinedComponent.scss";
import RequestDemoComponent from "../RequestDemo/requestDemoComponent";
import WhatIsQuestionComponent from "../WhatIsQuestion/whatIsQuestionComponent";
import CapabilitiesAccordionComponent from "../CapabilitiesAccordion/capabilitiesAccordionComponent";
import WhatsIncludedCatalogComponent from "../WhatsIncludedCatalog/WhatsIncludedCatalogComponent";
import HowItsUsedComponent from "../HowItsUsed/HowItsUsedComponent";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import SharePointService from "../../../../../../common/modules/service/SharePointService";
import TrainingNHelpComponent from "../../Training&HelpComponent/Training&Help";

export interface AnimationData {
    Title: string,
    HeaderDescription: string,
    DemoLink: {ButtonCaption: string, Email: string},
    PageName: string
}

const ConnectEventCombinedComponent = (props: {mainPage: string}) => {

    const {mainPage} = props;

    const [animationSectionData, setAnimationSectionData] = useState<AnimationData>({
        Title: "",
        HeaderDescription: "",
        DemoLink: {ButtonCaption: "", Email: ""},
        PageName: ""
      });
    
    const [capAccordionData, setCapAccordionData] = useState({
        Title: "",
        Question: "",
        Capabilities: [{
            Title: "",
            Description: [""]
        }]
    });

    const [whatIsQuestionData, setWhatIsQuestionData] = useState({
        Question: "",
        Header: "",
        Body: "",
        lastText: ""
    });

    const [howItsUsedData, setHowItsUsedData] = useState({});
    const [imageData, setImageData] = useState([{}]);

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [trainingInfo, setTrainingInfo]: any[] = useState([])
    const [helpInfo, setHelpinfo]: any[] = useState([])

    function getCardsData(adoptsId: number[]) {
      if(isAuthenticated) {
      let mySPService = new SharePointService();
      let filteredCardsData: any[] = [];
      mySPService.getAccelerators(instance,accounts)
        .then(response => response.json())
        .then(response => {
          if(adoptsId.length > 0) {
            adoptsId.forEach((id: number) => {
              response.value.map((ele: { ID: number; }) => {
                if(ele.ID === id) {
                  filteredCardsData.push(ele);
                }
              })
            })
            const mySPService = new SharePointService();
            let ListName = mySPService.ListNames.ACCELERATORS;
            let tempFilteredCardsData = [...filteredCardsData];
            let tempArr:any = [];
            filteredCardsData.forEach((item:any) => {
                if(item.CardImage !== null) {
                    let file = JSON.parse(item.CardImage)
                    mySPService.getAttachments(ListName, item.ID, encodeURI(file.fileName), instance, accounts)
                        .then((data: any) => {
                            item.imageURL = data;
                            tempArr.push(item);
                            const index = tempFilteredCardsData.findIndex((obj: any) => obj.Id === tempArr.Id);
                            if (index !== -1) {
                              tempFilteredCardsData[index] = tempArr
                            }
                            setImageData(tempFilteredCardsData);
                        })
                        .catch(error => {
                            console.log(error)
                        });
                        console.log(tempArr);
                }
            })
          }
        })
      }
    }

    const getStaticData = () => {
      let spService = new SharePointService();
      spService.getDevPortalStaticContents(instance, accounts)
          .then(response => response.json())
          .then((data: any) => {
              let trainingData: any = []
              let helpingData: any = []
              data.value.map((item: any) => {
                  if (item.Title === "SectionTraining") {
                      const itemData = JSON.parse(item.Content)
                      trainingData.push(itemData)
                  }
              })
              data.value.map((item: any) => {
                  if (item.Title === "SectionHelp") {
                      const helps = JSON.parse(item.Content)
                      helpingData.push(helps)
                  }
              })
              console.log(trainingData, helpingData);
              setTrainingInfo(trainingData)
              setHelpinfo(helpingData)
          }).catch((err) => {
              console.log(err)
          })
    }

    function fetchOnLoad() {
        let spService = new SharePointService();
        spService.getDevPortalPageDetails(instance, accounts).then(response => response.json())
          .then(response => {
            response.value.map((item: any) => {
              if (item.PageName === mainPage) {
                const tempHowItsUsed = item.HowItsUsed;
                const tempAdoptsId = item.AdoptsId;
                setAnimationSectionData({
                    Title: item.Title,
                    HeaderDescription: item.HeaderDescription,
                    DemoLink: JSON.parse(item.DemoLink),
                    PageName: item.PageName
                });
                const parsedWhatIsQuestionData = JSON.parse(item.WhatIs);
                setWhatIsQuestionData(parsedWhatIsQuestionData);
                const parsedCapAccordionData = JSON.parse(item.Capabilities);
                setCapAccordionData(parsedCapAccordionData);
                setHowItsUsedData(tempHowItsUsed);
                getCardsData(tempAdoptsId);
              }
            });
            getStaticData();
          })
          .catch((error) => {
          });
    }

    useEffect(() => {
      window.scrollTo(0, 0);
      fetchOnLoad();
    }, []);

    return (
        <div className="connect-event-complete-block">
            <RequestDemoComponent animationSectionData={animationSectionData} />
            <WhatIsQuestionComponent whatIsQuestionData={whatIsQuestionData} />
            <CapabilitiesAccordionComponent capAccordionData={capAccordionData} />
            <WhatsIncludedCatalogComponent mainPage={mainPage} cardsPerPage={6} seeAllPresent={true} />
            {imageData.length > 0 &&
              <HowItsUsedComponent howItsUsedData={howItsUsedData} imageData={imageData} />
            }
            <div className="nexus-flex-row training-container">
              {Object.keys(trainingInfo).length > 0 ? 
                <TrainingNHelpComponent trainings={trainingInfo} helpData={helpInfo} /> :
                <p>loading...</p>
              }
            </div>
        </div>
    )
}

export default ConnectEventCombinedComponent;
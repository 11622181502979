import React from "react";
import AccordionGroupComponent from "../../../../../../common/components/AccordionGroup/AccordionGroup";
import "./capabilitiesAccordionComponent.scss";

type CapabilitiesProps = {
    Title: string,
    Description: Array<string>
};

type CapabilitiesAccordionProps = {
    Title: string,
    Question: string,
    Capabilities: Array<CapabilitiesProps>,
};

const CapabilitiesAccordionComponent = (props: {capAccordionData: CapabilitiesAccordionProps}) => {

    const {capAccordionData} = props;

    return (
        <div className="nexus-row nexus-pb-5 nexus-px-4 cap-acc-block">
            {capAccordionData.Title !== "" &&
                <AccordionGroupComponent accordionData={capAccordionData} />
            }
        </div>
    )
}

export default CapabilitiesAccordionComponent;
import React from "react";
import "./whatIsQuestionComponent.scss";

type WhatIsQuestionProps = {
    Question: string;
    Header: string;
    Body: string;
    SecondaryBody?: string;
};

const WhatIsQuestionComponent = (props: {whatIsQuestionData: WhatIsQuestionProps}) => {

    const {whatIsQuestionData} = props;

    return (
        <div className="nexus-row nexus-py-6 nexus-px-4 what-is-block">
            <div className="nexus-col-lg-12">
                <h6 className="nexus-h6">{whatIsQuestionData && whatIsQuestionData.Question}</h6>
            </div>
            <div className="nexus-col-lg-12 nexus-my-4">
                <h3 className="nexus-h3">{whatIsQuestionData && whatIsQuestionData.Header}</h3>
            </div>
            <div className="nexus-col-lg-12">
                <h6 className="nexus-h6">{whatIsQuestionData && whatIsQuestionData.Body}</h6>
            </div>
            {whatIsQuestionData.SecondaryBody &&
                <div className="nexus-col-lg-12">
                    <h6 className="nexus-h6">{whatIsQuestionData && whatIsQuestionData.SecondaryBody}</h6>
                </div>
            }
        </div>
    )
}

export default WhatIsQuestionComponent;
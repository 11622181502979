import { HomeData, HomeSection } from "../Models/HomeModel";
import {
  NexusOperatingValue,
  NexusOperating,
  NexusOperatingContent,
} from "../Models/NexusOperatingModel";
import {
  IconConnect,
  IconEvent,
  IconData,
  IconAdoptHard,
} from "../../../common/assets/images/index";

const getContentData = (
  data: HomeData,
  title: string
): string | HomeSection | null | undefined => {
  return data ? data.value.find((item) => item.Title === title)?.Content : null;
};

const getSearchSectionTitle = (data: HomeData | null): string => {
  const content = data
    ? getContentData(data, "LandingPage_SearchSection")
    : null;
  return content ? JSON.parse(content as string).SectionTitle : "";
};

const getOperatingSystemHeaderData = (
  data: HomeData | null
): HomeSection | null => {
  const content = data
    ? getContentData(data, "LandingPageSection_NexusOperatingSystem")
    : null;
  return content ? JSON.parse(content as string) : null;
};

const getOperatingSystemValueData = (
  data: NexusOperating | null
): NexusOperatingContent[] | null => {
  return data ? transformOperatingSystemValueData(data.value) : null;
};

const transformOperatingSystemValueData = (
  data: NexusOperatingValue[]
): NexusOperatingContent[] => {
  return data.map((item) => {
    const pageName = item.PageName.toLocaleLowerCase();
    const id = item["odata.id"];
    const learnMoreText = `Learn More`;

    if (pageName === "connect") {
      return {
        id: id,
        title: item.Title,
        subtitle: item.ShortDescription,
        type: pageName,
        icon: IconConnect,
        showButton: true,
        buttonText: learnMoreText,
        showRibbon: false,
        size: "small",
      };
    }

    if (pageName === "event") {
      return {
        id: id,
        title: item.Title,
        subtitle: item.ShortDescription,
        type: pageName,
        icon: IconEvent,
        showButton: true,
        buttonText: learnMoreText,
        showRibbon: false,
        size: "small",
      };
    }

    if (pageName === "data") {
      return {
        id: id,
        title: item.Title,
        subtitle: item.ShortDescription,
        type: pageName,
        icon: IconData,
        showButton: true,
        buttonText: learnMoreText,
        showRibbon: true,
        size: "small",
      };
    }

    if (pageName === "adopt") {
      return {
        id: id,
        title: item.Title,
        subtitle: item.ShortDescription,
        type: pageName,
        icon: IconAdoptHard,
        showButton: true,
        buttonText: learnMoreText,
        showRibbon: false,
        size: "large",
      };
    }

    return {
      id: id,
      title: item.Title,
      subtitle: item.ShortDescription,
      type: pageName,
      icon: IconData,
      showButton: true,
      buttonText: learnMoreText,
      showRibbon: false,
      size: "small",
    };
  });
};

const getEyNexusContentData = (data: HomeData | null): HomeSection | null => {
  const content = data
    ? getContentData(data, "LandingPageSection_WhatIsEyNexus")
    : null;
  return content ? JSON.parse(content as string) : null;
};

const getGetStartedContentData = (
  data: HomeData | null
): HomeSection | null => {
  const content = data
    ? getContentData(data, "LandingPageSection_GetStarted")
    : null;
  return content ? JSON.parse(content as string) : null;
};

const getComingSoonText = (data: HomeData | null): string => {
  const content = data
    ? getContentData(data, "LandingPage_ComingSoonText")
    : null;

  return content ? content.toString() : "";
};

const getCommunityContentData = (data: HomeData | null): HomeSection | null => {
  const content = data ? getContentData(data, "SectionCommunity") : null;
  return content ? JSON.parse(content as string) : null;
};

const getSectionPrototypingAndSandboxData = (
  data: HomeData | null
): HomeSection | null => {
  const content = data
    ? getContentData(data, "SectionPrototypingAndSandbox")
    : null;
  return content ? JSON.parse(content as string) : null;
};

const getSectionTrainingData = (data: HomeData | null): HomeSection | null => {
  const content = data ? getContentData(data, "SectionTraining") : null;
  return content ? JSON.parse(content as string) : null;
};

export {
  getSearchSectionTitle,
  getOperatingSystemHeaderData,
  getOperatingSystemValueData,
  getEyNexusContentData,
  getGetStartedContentData,
  getComingSoonText,
  getCommunityContentData,
  getSectionPrototypingAndSandboxData,
  getSectionTrainingData,
};

/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable no-process-env */

import { spTokenRequest } from "../azureAuth/authConfig";

class SharePointService {
    SharePointSiteURL = "https://sites.ey.com/sites/GlobalCloud/EY_Nexus";

    // SharePoint List name : Data will be fetched from this all lists
    ListNames = {
        DEV_PORTAL_PAGE_DETAILS: "DevPortalPageDetails",
        DEV_PORTAL_STATIC_CONTENT: "DevPortalStaticContents",
        ADOPT_PAGE_DETAILS: "AdoptPageDetails",
        ADOPT_PAGE_STATIC_DETAILS: "AdoptPageStaticDetails",
        ACCELERATORS: "Accelerators",
        COMPONENTS: "Components",
        CONNECTORS: "Connectors",
        NEXUS_TECH_TRAINING: "NexusTechnicalTraining",
        NEXUS_TECH_BOOTCAMP: "NexusBootCamp",
        FILTERS: "Filters"
    };

    // This method will generate token for calling SharePoint API.
    async generateTokenForSPApi(instance: any, accounts: any) {

        return instance.acquireTokenSilent({
            ...spTokenRequest,
            account: accounts[0]
        })
    }

    getHeaders(token: any) {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");
        headers.append("Accept", "application/json");

        const options = {
            method: "GET",
            headers: headers,
        };

        return options;
    }

    // To Retrive card for Adopt page and How Its used section for Connect and Connector Page
    async getAccelerators(instance: any, accounts: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.ACCELERATORS}')/items?$select=ID,Title,Description,Category,Exploremore,Icon,FeatureCount,CardImage&$filter=ShowOnDevPortal eq 1&$orderby=Sequence asc`,
            options
        );
    }

    // To Retrive card data for Event page
    async getComponents(instance: any, accounts: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.COMPONENTS}')/items?$select=ID,Title,Description,Icon,Category&$orderby=Title asc`,
            options
        );
    }

    // To Retrive card data for connect page
    async getConnectors(instance: any, accounts: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.CONNECTORS}')/items?$select=ID,Title,Description,Category,Icon&$orderby=Title asc`,
            options
        );
    }

    // To retrieve Banner, WhatIs, Capabilities, HowIts Used Section data for Connect, Event and Adopt page
    async getDevPortalPageDetails(instance: any, accounts: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.DEV_PORTAL_PAGE_DETAILS}')/items?$select=*&$orderby=Sequence,Title asc`,
            options
        );
    }

    // To retrieve Landing page static data and Others comman sections like Trainning, NeedHelp , Community, Prototypic etc 
    async getDevPortalStaticContents(instance: any, accounts: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.DEV_PORTAL_STATIC_CONTENT}')/items?$select=*&$orderby=Title asc`,
            options
        );
    }

    // To Retrieve Selected adopt item details (This will return feature and strategy for selected Adopt item)
    async getAdoptPageDetails(instance: any, accounts: any, adoptItemId: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.ADOPT_PAGE_DETAILS}')/items?$select=Id,Title,Description,Section,Persona,ImgForDevPortal&$Filter=AdoptPage eq '${adoptItemId}'&$orderby=Sequence,Title asc`,
            options
        );
    }

    // To retrieve selected adopt item details (This will return feature and strategy for selected Adopt item)
    async getAdoptPageStaticDetails(instance: any, accounts: any, adoptItemId: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.ADOPT_PAGE_STATIC_DETAILS}')/items?$select=Persona,Content&$Filter=AdoptPage eq '${adoptItemId}'&$orderby=Title asc`,
            options
        );
    }

    // Get filter data which will be used in connect and event page
    async getFilters(instance: any, accounts: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.FILTERS}')/items?$select=ID,Title,Filters&$orderby=Filters asc`,
            options
        );
    }

    // Get technical trainning data which will be used in trainning page
    async getTechnicalTrainnigs(instance: any, accounts: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.NEXUS_TECH_TRAINING}')/items?$select=ID,Title,Description,TrainingLink,Image,ComingSoon&$orderby=Sequence asc`,
            options
        );
    }

    // Get technical trainning data which will be used in trainning page
    async getBootCamp(instance: any, accounts: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${this.ListNames.NEXUS_TECH_BOOTCAMP}')/items?$select=ID,Title,CourseObjective,CourseDuration,VideoLink&$orderby=Sequence asc`,
            options
        );
    }

    async getFilteredData(instance: any, accounts: any, listName: any, select: any, filter: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${listName}')/items?$select=${select}&$filter=${filter}`,
            options
        );
    }

    // This method will call the search api of sharepoint and get data based on search query, select fields, row limit and start row params.

    async getDataUsingSearchAPI(instance: any, accounts: any, searchQuery: any, selectProperties: any, rowLimit: any, startRow: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const options = this.getHeaders(token.accessToken);

        return fetch(
            `${this.SharePointSiteURL}/_api/search/query?querytext='${encodeURIComponent(searchQuery)}'&selectproperties='${selectProperties}'&startrow=${startRow}&rowlimit=${rowLimit}`,
            options
        );
    }


    // Get as blob URL 
    // listName: SharePoint List name
    // itemId: Integer ID of sharepoint item for which attachment needs to be downloaded
    // fileName: attachment file name
    // intance: MSAL instance this will be used to generate token
    // accounts: login account details this will be used to generate token
    async getAttachments(listName: any, itemId: any, fileName: any, instance: any, accounts: any) {
        const token = await this.generateTokenForSPApi(instance, accounts);
        const response = await fetch(
            `${this.SharePointSiteURL}/_api/lists/getByTitle('${listName}')/items(${itemId})/AttachmentFiles('${fileName}')/$value`,
            {
                headers: {
                    Authorization: `Bearer ${token.accessToken}`,
                    Accept: "application/json;odata=verbose",
                },
            }
        );
        const blob = await response.blob();

        return URL.createObjectURL(blob);
    }
}

export default SharePointService;

import React, { useEffect, useState } from "react";
import './catalogBlock.scss'
import laptop from '../../../../../common/assets/images/laptop.png'
import laptopPlaceHolder from '../../../../../common/assets/images/Laptop_placeholder.png'
import placeholdLap from '../../../../../common/assets/images/buildWithConfigStudio.png'


const CatalogBlock = (props: any) => {
    const [data, setData] = useState(props.sectionData)
    return (
        <>
            {data.map((item: any, idx: number) => (
                <div className="catalog-container" key={idx}>
                    <div className="nexus-flex-1 item-container">
                        <h4 className="header">{item.Title}</h4>
                        <p className="description">{item.Description}</p>
                        <a href={`adopt-details/${item.Id}`} className="nexus-btn-primary nexus-btn-medium nexus-my-2">Learn More</a>
                    </div>
                    <div className="nexus-flex-1">
                        {/* {item.imgUrl ? */}
                        <div className="image-mask-container">
                            <div className="image-mask">
                                <img src={item.imgUrl ? item.imgUrl : placeholdLap} alt="API Content" className="api-image" />
                                <img src={laptopPlaceHolder} alt="Laptop" className="laptop-mask" />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )



}

export default CatalogBlock